.contact-icon {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50% !important;
  object-fit: cover;
}

#contact-button {
  position: fixed;
  bottom: 86px;
  right: 10px;
}

.facebook-icon {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  object-fit: cover;
}

#facebook-link {
  position: fixed;
  bottom: 36px;
  right: 15px;
}

.box-1 {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
