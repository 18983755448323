.popover-content:hover {
  background-color: #bbe3eb;
}

.popover-content:hover span {
  color: white !important;
}

.popover-content:hover svg {
  color: white !important;
}

.swal2-container {
  z-index: 10000;
}
