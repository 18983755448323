#order-heading {
  background-color: #edf4f7;
  position: relative;
  border-top-left-radius: 25px;
  max-width: 800px;
  padding-top: 20px;
  margin: 30px auto 0px;
}

#order-heading .text-uppercase {
  font-size: 0.9rem;
  color: #777;
  font-weight: 600;
}

#order-heading .h4 {
  font-weight: 600;
}

#order-heading .h4 + div p {
  font-size: 0.8rem;
  color: #777;
}

.close {
  padding: 10px 15px;
  background-color: #777;
  border-radius: 50%;
  position: absolute;
  right: -15px;
  top: -20px;
}

.wrapper {
  padding: 0px 50px 50px;
  max-width: 800px;
  margin: 0px auto 40px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.table th {
  border-top: none;
}

.table thead tr.text-uppercase th {
  font-size: 0.8rem;
  padding-left: 0px;
  padding-right: 0px;
}

.table tbody tr th,
.table tbody tr td {
  font-size: 0.9rem;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
}

.table-responsive {
  height: 100px;
}

.list div b {
  font-size: 0.8rem;
}

.list .order-item {
  font-weight: 600;
  color: #6db3ec;
}

.list:hover {
  background-color: #f4f4f4;
  cursor: pointer;
  border-radius: 5px;
}

label {
  margin-bottom: 0;
  padding: 0;
  font-weight: 900;
}

/* button.btn {
  font-size: 0.9rem;
  background-color: #66cdaa;
}

button.btn:hover {
  background-color: #5cb99a;
} */

.price {
  color: #5cb99a;
  font-weight: 700;
}

p.text-justify {
  font-size: 0.9rem;
  margin: 0;
}

.row {
  margin: 0px;
}

.subscriptions {
  border: 1px solid #ddd;
  border-left: 5px solid #ffa500;
  padding: 10px;
}

.subscriptions div {
  font-size: 0.9rem;
}

@media (max-width: 425px) {
  .wrapper {
    padding: 20px;
  }

  body {
    font-size: 0.85rem;
  }

  .subscriptions div {
    padding-left: 5px;
  }

  img + label {
    font-size: 0.75rem;
  }
}

/* body {
  background-color: #141114;
  background-image: linear-gradient(335deg, black 23px, transparent 23px),
    linear-gradient(155deg, black 23px, transparent 23px),
    linear-gradient(335deg, black 23px, transparent 23px),
    linear-gradient(155deg, black 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
} */
