@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.c-pill {
  align-items: center;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;
  line-height: 1;
  overflow: hidden;
  padding: 0px 12px 0px 20px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;

  word-break: break-word;

  &:before {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }
}

.c-pill--success {
  background: #b4eda0;
}
.c-pill--success:before {
  background: #6bc167;
}
.c-pill--warning {
  background: #ffebb6;
}
.c-pill--warning:before {
  background: #ffc400;
}
.c-pill--danger {
  background: #ffd5d1;
}
.c-pill--danger:before {
  background: #ff4436;
}
.custom-popup {
  width: 1024px;
  max-width: 1024px !important; /* Đặt chiều rộng tối đa là 1024px */
}
.css-onl3ck {
  grid-column: span 5 / span 5;
  border-width: 1px;
  border-radius: 5px;
  border-color: #e2e8f0;
  padding-inline: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.css-k3gz8i {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
}
.css-zd4of5 {
  grid-column: span 1 / span 1;
}
.css-1a824c8 {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 200;
  margin-bottom: 5px;
}
.css-1ad58zi {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 200;
}
.css-191mp66 {
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: #01b574;
  width: 12px;
  height: auto;
  margin-right: 5px;
}
.css-191rrlz {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  --card-bg: #ffffff;
  background-color: #e2e8f0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.375rem;
  color: #1a202c;
  border-width: var(0, 0);
  border-color: var(--card-border-color);
  --card-radius: 0.375rem;
  --card-padding: 1.25rem;
  --card-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}
.css-ab50tw {
  height: 450px;
}
.css-1dzhzym {
  font-size: 1rem;
  color: red;
  font-weight: 200;
  margin-top: 50px;
}
.css-mj29dz {
  font-size: 1rem;
  color: red;
  font-weight: 200;
}
.css-1x60hrx {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  margin: var(--Icon-margin);
  font-size: var(--Icon-fontSize, var(--joy-fontSize-xl2));
  color: var(
    --Icon-color,
    var(--joy-palette-text-icon, var(--joy-palette-neutral-500, #636b74))
  );
}
#banking_code {
  color: red;
}

.swal2-container {
  z-index: 9999999 !important;
}
